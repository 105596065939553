body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;
}

.color-groups {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px auto;
  padding: 20px 10px;
}

.color-groups h3 {
  display: inline;
  position: absolute;
  top: -33px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}

.color-groups span {
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

span.active {
  border-bottom: 4px solid #ccc;
}

.list-colors {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 8fr));
  grid-gap: 10px;
}

.color-box {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.copy-text {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 32px;
  height: 32px;
  opacity: 0;
}

.color-box:hover .copy-text {
  opacity: 100%;
  transition: all linear 0.5s;
}

.light * path {
  fill: whitesmoke;
}
